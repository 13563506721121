* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
}
header {
  height: 70px;
  border-bottom: groove;
  border-bottom-width: 1px;
  max-width: 800px;
  align-self: center;
  width: 100%;
}
footer {
  display: inline-flex;
  width:100%;
  max-width: 800px;
  height:157px;
  margin-top: auto;
  border-top: groove;
  border-top-width: 1px;
  background-color: #ECEFF1;
  align-self: center;
}
.wrapper {
  flex-flow: column;
  min-height: 100vh;
  display: flex;
  position: relative;
}
a {
  color: #000000;
  text-decoration: none;
}
li {
	list-style-type:none;		/*リストマーカー無しにする*/
}
.fz13 {
  font-size: 13px;
}
.fz14 {
  font-size: 14px;
}
.fz18 {
  font-size: 18px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb30 {
  margin-bottom: 30px;
}
.mr10 {
  margin-right: 10px;
}
.mr30 {
  margin-right: 30px;
}
.mt16 {
  margin-top: 16px;
}
.mt30 {
  margin-top: 30px;
}
.mt100 {
  margin-top: 100px;
}
.pt100 {
  padding-top: 100px !important;
}
.pd30 {
  padding: 30px;
}
.flex {
  display: flex;
}
.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.App {
  width: 100%;
  max-width: 800px;
  font-family: auto;
  align-self: center;
  background-color: #FBFCFF;
}

.App-header {
  text-align: left;
  padding: 20px 0px;
}

.App-section {
  text-align: left;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.1);
  padding: 20px 40px;
  margin-bottom: 20px;
}

.App-footer {
  text-align: right;
  padding: 0;
  margin-bottom: 20px;
}

.aws-logo {
  height: 1.9em;
  vertical-align: middle;
}

.label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.comp {
  box-sizing: border-box;
  height: 136px;
  left: 30px;
  top: 385px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.comp-label {
  font-size: 16px;
  margin-bottom: 8px;
}
.comp-input {
  margin: 30px 0;
}
.comp-detail {
  box-sizing: border-box;
  /*height: 570px;*/
  left: 30px;
  top: 147px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 64px;
  padding-bottom: 5px;
}
.comp-detail-short {
  height: auto;
  margin-bottom: 32px;
  padding-bottom: 5px;
}
.comp-detail-title {
  font-weight: bold;
  display: flex;
  height: 72px;
  padding: auto;
  font-size: 18px;
  padding: 22px 20px;
}
.comp-detail-content {
  margin: 24px 16px;
}
.comp-detail-date {
  margin-top: 3px;
  background-image: url(images/emoji_events_black_24dp.png);
}
.comp-detail-calnder {
  background-image: url(images/event_black_24dp.png); 
}
.comp-detail-info {
  background-image: url(images/info_black_24dp.png); 
  padding-left: 22px;
}
.comp-detail-person {
  background-image: url(images/person_black_24dp.png); 
}
.comp-detail-group {
  background-image: url(images/group_black_24dp.png); 
}
.comp-detail-check {
  background-image: url(images/check_circle_black_24dp.png); 
}
.delete-confirm {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  color: #3949AB;
  font-weight: bold;
}
.delete-confirm:hover,
.delete-confirm:active {
  background: rgba(0, 0, 0, 0.1);
}
.done-check {
  display: block;
  width: 64px;
  height: 64px;
  margin: auto;
  margin-bottom: 30px;
  background-image: url(images/check_circle_white_24dp.png);
}
.exclamation-image {
  display: block;
  width: 64px;
  height: 64px;
  margin: auto;
  margin-bottom: 30px;
  background-image: url(images/error_black_24dp.png);

}
.emoji-image {
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 6px;
}
.textbox {
  font-size: 16px;
  border: 1px solid #999999;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 20px;
}
.btn {
  border-radius: 30px;
  font-weight: 700;
  text-align: center;
  width: -webkit-fill-available;
  height: 40px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.btn:disabled {
  cursor: default;
  color: #000;
}
.short-btn {
  width: 100px;
}
.clear-btn,
.clear-btn:focus,
.clear-btn:visited {
  background-color: #ffffff !important;
  color: #000000 !important;
  margin-right: 20px;
  border: 1px solid #AEB3B7 !important;
  width: 72px;
}
.clear-btn:active,
.clear-btn:hover {
  background-color: #cfcfcf !important;
  border: 1px solid #AEB3B7 !important;
}
.cancel-btn,
.cancel-buton:focus,
.cancel-buton:visited {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #AEB3B7 !important;
}
.cancel-btn:active,
.cancel-btn:hover {
  background-color: #cfcfcf !important;
  border: 1px solid #AEB3B7 !important;
}
.float-right {
  float: right;
  display: flex;
}
button a {
  color: #ffffff;
  display: block;
  width: 100%;
  height: 100%;
}
.button {
  font-size: 16px;
  background: linear-gradient(#FFFFFF, #CCCCCC);
  border: 1px solid #999999;
  border-radius: 4px;
  min-width: 100px;
  padding: 8px;
  margin-bottom: 20px;
  cursor: pointer;
}

.button:hover {
  border: 1px solid #000000;
}

.footer {
  margin: 24px 30px;
  width: 100%;
}

.copyright {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 6px;
}

.table {
  border: none;
  border-collapse: separate;
  border-spacing: 1px;
  padding: 0;
  margin-bottom: 20px;
}
table.comp-table {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  border-spacing: 0;
  border-top: 2px solid #CCCCCC;
  border-left: 2px solid #CCCCCC;
}
table.comp-table th,
table.comp-table td {
  padding: 15px 10px;
  border-right: 2px solid #CCCCCC;
  border-bottom: 2px solid #CCCCCC;
  text-align: left;
}

.table-header {
  font-size: 16px;
  text-align: center;
  color:#FFFFFF;
  background: #3366FF;
  border: none;
  min-width: 200px;
  padding: 8px;
}

.table-data {
  font-size: 16px;
  text-align: left;
  color:#000000;
  background: #99CCFF;
  border: none;
  min-width: 200px;
  padding: 8px;
}

.entry-table-block {
  width: 100%;
  overflow-x: auto;
}
.entry-table-block table {
  background: #FFFFFF;
  margin-bottom: 15px;
}
.entry-table-block table tbody tr.is-mine {
  background: #FBE9E7;
}
.entry-table-block table tbody tr td {
  white-space: nowrap;
}
.entry-table-event-name {
  font-weight: bold;
  margin-bottom: 15px;
}
.entry-table-type {
  margin-bottom: 15px;
}

.comp-title {
  font-weight: bold;
  display: flex;
}
.main {
  padding: 30px;
}
.menu {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: none;
  z-index: 1001;
}
.menu-bg {
  background-color: rgba(100, 100, 100, 0.5);
}
.menu > .content {
  background-color: white;
  width: 90%;
  height: 100%;
  float: right;
  transform: translateX(100%);
}
.menu > .content ul{
  margin: 0 35px;
}
.menu.show-menu {
  display: block;
}
.show-menu > .content {
  animation-name: slidein;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes slidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
.main-box {
  height: 310px;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
#menu .tb-border-menu:nth-child(1) {
  text-align: right;
  background-image: none;
  height: 72px;
  padding-top: 22px;
}
#menu .tb-border-menu {
  height: 87px;
  padding-top: 30px;
  font-weight: bold;
}
.menu-link {
  display: inline-block;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  padding-top: 30px;
}
.logout {
  display: contents;
}
.header-title {
  margin: auto;
  font-weight: bold;
}
.header-content {
  display: flex;
  padding-top: 22px;
}
.tb-border {
  font-family: auto;
  padding: 24px 16px;
  margin: 16px 0;
  background-size: 35px;
  background-position: right 0 top 50%;
  background-repeat: no-repeat;
  background-image: url(images/navigate_next-24px.svg);
}
.tb-border-menu:nth-child(1)  {
  text-align: right;
  background-image:  none;
  padding-right: 13px;
  padding-top: 30px;
}
.tb-border-menu {
  background-position: right 0 top 50%;
  background-repeat: no-repeat;
  background-size: 35px;
  border-bottom: groove;
  border-bottom-width: thin;
  font-family: auto;
  height: 87px;
  font-weight: bold;
  background-image: url(images/navigate_next-24px.svg);
  cursor: pointer;
}
::marker {
  display: none;
}
.input {
  width: -webkit-fill-available;
  height: 52px;
  padding-left: 10px;
}
.main .input {
  border: 2px solid #AEB3B7;
  border-radius: 5px;
}
.main .input:invalid {
  border: 2px solid #EF5350;
}
.main div.alert {
  color: #EF5350;
  padding: 10px 0 0 0;
}
.main .input.alert {
  border: 2px solid #EF5350;
}
.create_calendar:invalid {
  border: 2px solid #EF5350;
}
.contact-input {
  height: 100px;
}
.title {
  margin-bottom: 30px;
  font-size: 20px;
}
.grid {
  width: 300px;
  display: grid;
}
.button-menu {
  width: 20px;
  display: block;
  border: none;
  background: none;
  background-image: url(./images/menu-24px.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.button-back {
  width: 25px;
  margin-left: 30px;
  display: block;
  border: none;
  background: none;
  background-image: url(./images/arrow_back_ios_black_24dp.png);
  background-repeat: no-repeat;
  background-position: center;
}
header .button-menu {
  margin-right: 30px;
}
.comp-checkbox {
  display: flex;
}
.comp-checkbox div {
  width: 70%;
}
.entry-block {
  padding: 15px;
  margin-bottom: 30px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.entry-block .comp-title {
  margin: 0 0 10px;
}
.entry-block .entry-select {
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.entry-block .entry-select label {
  padding: 10px 0;
}
.entry-block .entry-select select {
  width: 60%;
  padding: 10px 20px;
  border-radius: 5px;
  border-color: #999999;
}
.entry-block .btn {
  margin-bottom: 0;
}

.event-block {
  padding: 2vh 3vw;
  margin: 2vh 0 3vh;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.event-line {
  color: #0D1A26;
  height: 50px;
  line-height: 50px;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
}
.event-line div:first-child {
  width: calc(100% - 24px - 15px);
  display: flex;
  justify-content: space-between;
}
.event-line:first-child div:first-child {
  border-bottom: 2px solid #AEB3B7;
}
.event-line div:last-child {
  width: 24px;
}
.event-line:first-child div span {
  padding: 0 15px;
  font-weight: bold;
}
.event-line div input {
  width: 100%;
  padding: 0 10px;
}
.event-line div.set-line > span {
  flex-grow: 2;
  line-height: normal;
  align-self: center;
  max-width: 58%;
}
.event-line div.set-line > div {
  flex-grow: 0;
  width: 40px;
}
.event-line div.set-line input {
  width: 25%;
  flex-grow: 0;
}
.icon {
  width: 0px;
  height: 0px;
  padding: 25px 12px; /* height:40px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  cursor: pointer;
}
.icon.add {
  background-image: url(images/add_circle_black_24dp.png);
}
.icon.cancel {
  background-image: url(images/cancel_black_24dp.png);
}

.footer-list {
  display: flex;
  place-content: center;
}
.footer-list div {
  margin-left: 8px;
  margin-right: 8px;
}
.welcome {
  height: 148px;
  max-width: 800px;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.welcome-text {
  padding-left: 30px;
  padding-top:  30px;
  font-size: 18px !important;
}
.welcome-contract {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  position: absolute;
  top: 30px;
  right: 30px;
}
.color {
  color: aliceblue;
  background: linear-gradient(90deg, #7986CB, #F06292 100%);
  font-weight: bold;
  z-index: 1;
}
.san {
  align-self: center;
  margin-left: 12px;
}
.search-name {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  border: 1px solid #AEB3B7;
  border-radius: 3px;
}
.calendar {
  width: inherit;
  padding-left: 10px;
  background-image: url(./images/calender_black_24dp.png);
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 93%;
  border: 1px solid #AEB3B7;
  border-radius: 3px;
}
.search_calendar {
  height: 42px;
}
.create_calendar {
  height: 52px;
}
.link {
  text-decoration-line: underline;
color: #3949AB;
}
.mandatory {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 38px;
  left: 307px;
  top: 288px;
  background: #F06292;
  border-radius: 20px;
  color: #FFFFFF;
  font-weight: bold;
  float: right;
}
.bulk_btn {
  position: sticky;
  bottom:0;
  z-index: 1000;
  background-color: #FFFFFF;
  width: 100%;
  padding: 15px 30px;
  max-width: 800px;
  margin-bottom: 30px;
}
.accordion__button {
  background-color: #FFFFFF !important;
}
.accordion__button:before {
  float: right;
  height: 7px !important;
  width: 7px !important;
  margin-top: 5px;
  transform: rotate(45deg) !important;
  margin-left: 40px;
  margin-right: 10px;
}
.accordion__box {
  box-sizing: border-box;
    left: 30px;
    top: 385px;
    background: #FBFCFF;
    box-shadow: 0px 0px 4px 3px rgb(0 0 0 / 10%);
    border-radius: 4px;
}
.accordion-answer{
  padding: 18px;
  width: 85%;
}
.accordion__button[aria-expanded='true']::before {
  transform: rotate(-135deg) !important;
  margin-top: 10px;
}
.amplify-field-group__inner-end {
  display: none;
}
.ReactModal__Overlay--after-open {
  background-color: rgba(87, 87, 87, 0.75) !important;
}
.ReactModal__Content--after-open {
  margin: auto;
}
.modal-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
}
.modal-button a {
  color: #3949AB;
  font-weight: bold;
}
.menu .amplify-icon > svg {
  font-size: large;
}
.done-entry {
  display: flex;
  gap: 10px;
  background: #B8CEF9;
  border-radius: 32px;
  width: 42%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  font-size: 12px;
}
.disabled-btn {
  background-color: #DDDDDD !important;
  cursor: auto;
}
.disabled-btn:disabled {
  color: #FFFFFF;
}
.disabled-btn:active,
.disabled-btn:hover {
  color: #FFFFFF;
  background-color: #DDDDDD !important;
}
.hide-btn {
  width: 0;
  height: 0;
  display: none;
}
.underbar {
  text-decoration: revert;
  color: #3949AB;
}
.white {
  background-color: #EFF0F0;
}
.hideMe {
  -moz-animation: cssAnimation 0s ease-in 2s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 2s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 2s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 2s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
/* auth */
.auth-wrapper {
  margin-top: 37px;
}

.amplify-button--primary {
  margin-top: 30px;
  border-radius: 30px;
  border: none;
  position: relative;
}
.mypage-button .amplify-button--primary {
  z-index: 2;
}
:root,
[data-amplify-theme],
[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: rgba(57, 73, 171, 1);
  --amplify-components-button-primary-active-background-color: rgba(37, 43, 151, 1);
  --amplify-components-button-primary-visited-background-color: rgba(57, 73, 171, 1);
  --amplify-components-button-primary-focus-background-color: rgba(57, 73, 171, 1);
  --amplify-components-button-primary-hover-background-color: rgba(37, 43, 151, 1);
}

.auth-wrapper {
place-self: center;
}
[data-amplify-authenticator] {
  margin: 35px;
}
.detail-detail {
  overflow-wrap: anywhere;
}
.gray_check .amplify-checkbox__icon--checked {
  background-color: #999999;
}
.gray_check .amplify-checkbox {
  cursor: default;
}
@keyframes cssAnimation {
  to {
      width:0;
      height:0;
      overflow:hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
      width:0;
      height:0;
      visibility:hidden;
  }
}


.nickname {
  width: 80%;
}
.nickname-detail {
  overflow-wrap: break-word;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
